import React from 'react'
import Layout from '@components/Layout'
import { Seo } from '@components/Seo'
import { graphql } from 'gatsby'
import PageTitle from '@components/PageTitle'
import { Article, Content } from '@global'

const StandardPageTemplate = ({ data }) => {
  const page = data.datoCmsStandardPage
  const seotitle = page.title + ' | In Sowerby Bridge The Town and Business Guide For Residents and Visitors'

  return (
    <Layout>
      <Article>
        <Seo title={seotitle} />
        <PageTitle title={page.title} />
        <Content
          dangerouslySetInnerHTML={{
            __html: page.contentNode.childMarkdownRemark.html,
          }}
        />
      </Article>
    </Layout>
  )
}

export const standardpagegraph = graphql`
  query standardpagequery($slug: String!) {
    datoCmsStandardPage(slug: { eq: $slug }) {
      id
      title
      contentNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export default StandardPageTemplate
